<template>
  <v-card style="border-radius: 8px" class="shadow product_card mt-5">
    <v-card-text>
      <!-- <v-row>
        <v-col cols="11"> </v-col>
        <v-col cols="1">
          <LightningBoltIcon :class="is_public ? '' : 'fill-red'" />
        </v-col>
      </v-row> -->

      <v-row dense>
        <v-col cols="12">
          <div>
            <div class="d-flex justify-space-between">
              <p class="font-semibold text-lg black--text m-b-4 mt-3 ml-2">
                <span class="line-clamp-1">{{ unique_id }}</span>
              </p>

              <div class="align-items-center" style="margin-top: -11px">
                <LightningBoltIcon
                  :class="status_id == 1 ? '' : 'fill-red'"
                  style="margin-right: 10px"
                  @click.stop="enableDisableVehicle"
                />
                <v-menu content-class="q-menu" right top>
                  <template v-slot:activator="{ on, attrs }">
                    <DotsIcon v-bind="attrs" v-on="on" />
                  </template>
                  <v-list>
                    <v-list-item
                      @click="
                        $emit('edit', {
                          index: index,
                        })
                      "
                    >
                      Edit
                    </v-list-item>
                    <v-divider class="mb-2" />
                    <v-list-item @click="move('right')">
                      Move Right
                    </v-list-item>
                    <v-divider class="mb-2" />
                    <v-list-item @click="move('left')"> Move Left </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <p class="font-medium m-1 p-0 ml-2">
              <span class="text-elepsis">Order {{ order ? order : "0" }}</span>
            </p>
            <p class="font-medium m-1 p-0 ml-2">
              <span class="text-elepsis">{{ name ? name : "--" }}</span>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-bottom-navigation light v-if="1 == 2">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="
          $emit('edit', {
            index: index,
          })
        "
      >
        <span>Edit</span>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        text
        @click="
          $emit('delete', {
            index: index,
          })
        "
      >
        <span>Delete</span>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn text>
        <span>View</span>
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-spacer></v-spacer> -->
    </v-bottom-navigation>
  </v-card>
</template>

<script>
import DotsIcon from "@/assets/images/misc/h-options.svg";
import LightningBoltIcon from "@/assets/images/facilities/lightning-bolt.svg";
//import DotsIcon from "@/assets/images/misc/h-options.svg";
// TODO:
export default {
  components: { LightningBoltIcon, DotsIcon },
  props: {
    unique_id: { type: String, default: "" },
    name: { type: String, default: "" },
    pits: { type: Number },
    description: { type: String, default: "" },
    pit_in: { type: Boolean, default: false },
    pit_out: { type: Boolean, default: false },
    lap: { type: Boolean, default: false },
    order: { type: Number },
    //twoWayId: { type: Number },
    boosterId: { type: Number },

    x_position: { type: String, default: "" },
    y_position: { type: String, default: "" },
    latitude_0: { type: String, default: "" },
    latitude_1: { type: String, default: "" },
    longitude_0: { type: String, default: "" },
    longitude_1: { type: String, default: "" },
    index: { type: Number },
    id: { type: Number },
    status_id: { type: Number },
    venueServiceId: { type: Number },
  },

  methods: {
    move(direction) {
      this.$emit("order", {
        id: this.id,
        direction: direction,
      });
    },
    enableDisableVehicle() {
      this.$emit("enableOrDisable", {
        id: this.id,
      });
    },
  },
};
</script>
<style scoped>
.product_details {
  font-size: 30px;
}
.product_card {
  border: 1px solid rgba(17, 42, 70, 0) !important;
  cursor: pointer;
}
.fill-red {
  fill: red !important;
}
.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}
.product_card:hover {
  border: 1px solid rgba(17, 42, 70, 1) !important;
}
</style>