<template>
  <v-container fluid>
    <FacilitiesTabs
      :configurations="configurations"
      :show-add-loop="true"
      :venue-service-id="venueServiceId"
      :venue-services="venueServices"
      @serviceChange="serviceChange"
      @showLoopDialog="(value) => (loopDialog = value)"
      @update:venueServiceId="(value) => (venueServiceId = value)"
    />

    <v-divider
      class="mt-4"
      style="border-color: rgba(17, 42, 70, 0.14) !important"
    />

    <v-row>
      <template v-for="(loop, ci) in loops">
        <v-col :key="loop.id" lg="4" md="4" xl="3">
          <loop-widget
            :key="ci"
            :index="ci"
            :service="serviceName"
            v-bind="loop"
            @delete="deleteLoop"
            @edit="editLoop"
            @order="setOrder"
            @enableOrDisable="enableOrDisableLoop"
          ></loop-widget>
        </v-col>
      </template>
    </v-row>

    <v-row v-if="1 == 2">
      <v-col>
        <v-expansion-panels
          :value="openTabs"
          accordion
          flat
          hover
          light
          multiple
          tile
        >
          <template v-for="(loop, ci) in loops">
            <v-row :key="ci">
              <v-col>
                <loop-widget
                  :key="pi"
                  :cindex="ci"
                  :index="pi"
                  :service="serviceName"
                  v-bind="loop"
                  @delete="deleteLoop"
                  @edit="editLoop"
                ></loop-widget>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <loop-creation
      v-if="loopDialog"
      :is-enable-add-loop="loopDialog"
      :venue-service-id="venueServiceId"
      v-bind="loopData"
      @close="loopClose"
      @deleteLoop="deleteLoop"
      @save="loopSave"
    />
  </v-container>
</template>

<script>
import LoopWidget from "@/components/Loop/LoopWidget";
import LoopCreation from "@/components/Facility/LoopCreation";
import constants from "@/utils/constants";
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";

export default {
  components: {
    FacilitiesTabs,
    LoopWidget,
    LoopCreation,
  },

  data: () => ({
    venueServiceId: null,

    openTabs: [0],
    valid: false,
    timingConfiguration: [
      { name: null, days: [], to_time: null, from_time: null },
    ],

    timeData: constants.TIMINGS,
    loopDialog: false,
    loops: [],
    loop: {},
    loopData: {},
    configurations: {},
    projectNumber: null,
    transactionType: null,
  }),

  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((res) => {
        if (res.length) {
          this.checkVenueService();
        }
      });
    } else {
      this.checkVenueService();
    }
    if (this.$store.getters.venueInfo) {
      if (this.$store.getters.venueInfo.enable_dct_erp) {
        this.isEnableDctErp = true;
        if (this.$store.getters.venueInfo.dct_erp_configuration) {
          this.projectNumber =
            this.$store.getters.venueInfo.dct_erp_configuration.project_no;
          this.transactionType =
            this.$store.getters.venueInfo.dct_erp_configuration.transaction_type;
        }
      } else {
        this.isEnableDctErp = false;
      }
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
    serviceName() {
      let service = this.venueServices.find(
        (item) => item.venue_service_id == this.venueServiceId
      );
      return service ? service.name : "";
    },
  },
  methods: {
    serviceChange() {
      this.getLoops();
      this.initializeFacilityServices();
    },
    checkVenueService() {
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceId = data.venue_service_id;
      } else {
        this.venueServiceId =
          this.$store.getters.getSportsService[0].venue_service_id;
      }
      setTimeout(() => {
        this.getLoops();
        this.initializeFacilityServices();
      }, 10);
    },
    initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
          .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200) {
              let data = this.$store.getters.getConfigurationByVenueServiceId(
                this.venueServiceId
              );
              this.configurations = data;
              this.$forceUpdate();
            }
          });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
          this.venueServiceId
        );
        this.configurations = data;
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },

    addLoop() {
      this.loopData.quantity = 1;
      this.loopData.editFlag = false;
      this.loopDialog = true;
    },
    setOrder(data) {
      this.showLoader("Loading");
      this.$http
        .get(
          `venues/mylaps/loops/reorder/${data.id}?direction=${data.direction}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.getLoops();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    editLoop(data) {
      const loopData = this.loops[data.index];
      this.loopData.loop_id = loopData.loop_id;
      this.loopData.id = loopData.id;
      this.loopData.unique_id = loopData.unique_id;
      this.loopData.name = loopData.name;
      this.loopData.description = loopData.description;
      this.loopData.pits = loopData.pits;
      this.loopData.pit_in_enabled = loopData.pit_in_enabled;
      this.loopData.pit_out_enabled = loopData.pit_out_enabled;
      this.loopData.lap_enabled = loopData.lap_enabled;
      //this.loopData.two_way_id = loopData.two_way_id;
      // this.loopData.booster_id = loopData.booster_id;
      this.loopData.booster_id = 0;
      this.loopData.x_position = loopData.x_position;
      this.loopData.y_position = loopData.y_position;
      this.loopData.gate_time = loopData.gate_time;
      this.loopData.latitude_0 = loopData.latitude_0;
      this.loopData.latitude_1 = loopData.latitude_1;
      this.loopData.longitude_0 = loopData.longitude_0;
      this.loopData.longitude_1 = loopData.longitude_1;
      this.loopData.editFlag = true;

      setTimeout(() => {
        this.loopDialog = true;
      }, 10);
    },
    enableOrDisableLoop(data) {
      this.$http
        .put(`venues/mylaps/loops/${data.id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.getLoops();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getLoops() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/mylaps/loops/${this.venueServiceId}`)
        .then((response) => {
          this.loops = [];
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.loops = response.data.data;
            console.log("List of loops");
            console.log(this.loops);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteLoop(loopId) {
      // const searchProductById = this.productCategories
      //         .flatMap(category => category.products)
      //         .find(product => product.product_id === productId);
      this.confirmModel = {
        id: loopId,
        title: "Do you want to delete this product?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_product",
      };
    },
    loopClose() {
      this.loopDialog = false;
      this.loopData = {};
    },
    loopSave() {
      this.loopDialog = false;
      this.loopData = {};
      this.getLoops();
    },
  },
};
</script>
<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.v-expansion-panel-header--active {
  color: #00b0af;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.month_names {
  background-color: rgb(1, 78, 97);
  padding: 6px 8px;
  border-radius: 5px;
  color: white;
}

.v-expansion-panel-header--active .month_names {
  background-color: rgb(4, 148, 184);
}

.tab_is_active {
  color: #112a46;
  font-weight: 600;

  svg {
    opacity: 1 !important;
  }
}

.q-tab-nav {
  svg {
    fill: none !important;
    stroke: black !important;
    opacity: 0.4;
    min-width: 20px;
  }
}

.active_category {
  background-color: rgba(79, 174, 175, 0.1) !important;
  color: rgba(79, 174, 175, 1) !important;
  border: 1px solid rgba(79, 174, 175, 1) !important;
}
</style>
