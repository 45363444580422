<template>
  <v-dialog persistent v-model="isEnableAddLoop" width="40%">
    <v-form ref="loop_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <p class="text-2xl font-semibold" style="color: black">
                  Add Loop
                </p>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="titles mt-5" style="color: black">Loops</div>
          <v-card class="shadow-0 bordered mt-2">
            <v-card-text>
              <v-row dense>
                <v-col cols="4" md="4" sm="12" class="pb-0">
                  <label for=""> Unique ID </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.unique_id"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Unique ID is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="12" class="pb-0">
                  <label for=""> Name </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.name"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="12">
                  <label for="">Pits</label>
                  <v-select
                    v-model="loop.pits"
                    :items="[
                      { text: 'True', value: 1 },
                      { text: 'False', value: 0 },
                    ]"
                    item-text="text"
                    item-value="value"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    outlined
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    :rules="[
                      (v) =>
                        (v !== null && v !== undefined) || 'Pits is required',
                    ]"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12" sm="12">
                  <label for="">Description</label>
                  <v-textarea
                    rows="4"
                    v-model="loop.description"
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                  ></v-textarea>
                </v-col>
                <v-col cols="4" md="4" sm="12" class="pb-0 mt-5 ml-3">
                  <v-row>
                    <v-checkbox
                      class="mt-0 pt-0"
                      v-model="loop.pit_in_enabled"
                      label="Pit In"
                      @change="handleCheckboxChange('pit_in')"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-checkbox
                      class="mt-0 pt-0"
                      v-model="loop.pit_out_enabled"
                      label="Pit Out"
                      @change="handleCheckboxChange('pit_out')"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-checkbox
                      class="mt-0 pt-0"
                      v-model="loop.lap_enabled"
                      label="Lap"
                      @change="handleCheckboxChange('lap')"
                    ></v-checkbox>
                  </v-row>
                </v-col>
              </v-row> </v-card-text
          ></v-card>
          <div class="titles mt-5" style="color: black">ID & Booster</div>
          <v-card class="shadow-0 bordered mt-2">
            <v-card-text>
              <v-row>
                <!-- <v-col cols="4" md="4" sm="12">
                  <label for=""> Two Way ID </label>
                  <v-select
                    v-model="loop.two_way_id"
                    :items="twoWayIds"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    outlined
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :disabled="true"
                    :rules="[
                      (v) =>
                        (v !== null && v !== undefined) || 'Id is required',
                    ]"
                  ></v-select>
                </v-col> -->

                <v-col cols="4" md="4" sm="12">
                  <label for="">Booster</label>
                  <v-select
                    v-model="loop.booster_id"
                    :items="[{ text: 'False', value: 0 }]"
                    item-text="text"
                    item-value="value"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    outlined
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :disabled="true"
                    :rules="[
                      (v) =>
                        (v !== null && v !== undefined) ||
                        'Booster is required',
                    ]"
                  >
                    ></v-select
                  >
                </v-col>
              </v-row>
            </v-card-text></v-card
          >
          <div class="titles mt-5" style="color: black">
            Location and Access Timing
          </div>
          <v-card class="shadow-0 bordered mt-2 pb-3">
            <v-card-text>
              <v-row>
                <v-col cols="3" md="3" sm="12" class="pb-0">
                  <label for=""> x-position </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.x_position"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Position is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" md="3" sm="12" class="pb-0">
                  <label for=""> y-position </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.y_position"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Position is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" md="3" sm="12" class="pb-0">
                  <label for=""> Gate Time </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.gate_time"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Gate time is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="3" sm="12" class="pb-0">
                  <label for=""> Latitude 0 </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.latitude_0"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Latitude is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" md="3" sm="12" class="pb-0">
                  <label for=""> Latitude 1 </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.latitude_1"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Latitude is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" md="3" sm="12" class="pb-0">
                  <label for=""> Longitude 0 </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.longitude_0"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Longitude is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="3" sm="12" class="pb-0">
                  <label for=""> Longitude 1 </label>
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="loop.longitude_1"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Longitude is required']"
                  ></v-text-field>
                </v-col>
              </v-row> </v-card-text
          ></v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn text class="ma-2 white--text blue-color" @click="addLoop"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>
<script>
import constants from "@/utils/constants";

export default {
  components: {},
  props: {
    unique_id: { type: String, default: "" },
    name: { type: String, default: "" },
    pits: { type: Number },
    description: { type: String, default: "" },
    pit_in: { type: Boolean, default: false },
    pit_out: { type: Boolean, default: false },
    lap: { type: Boolean, default: false },
    pit_in_enabled: { type: Number },
    pit_out_enabled: { type: Number },
    lap_enabled: { type: Number },
    order: { type: Number },
    //two_way_id: { type: Number, default: 1 },
    booster_id: { type: Number, default: 0 },
    gate_time: { type: String, default: "" },
    x_position: { type: String, default: "" },
    y_position: { type: String, default: "" },
    latitude_0: { type: String, default: "" },
    latitude_1: { type: String, default: "" },
    longitude_0: { type: String, default: "" },
    longitude_1: { type: String, default: "" },
    index: { type: Number },
    id: { type: Number },
    status_id: { type: Number },
    venueServiceId: { type: Number },
    isEnableAddLoop: { type: Boolean, default: false },
    editFlag: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
  },
  data() {
    return {
      boosters: [
        { id: 1, name: "Booster 1" },
        { id: 2, name: "Booster 2" },
        { id: 3, name: "Booster 3" },
      ],
      twoWayIds: [
        { id: 1, name: "ID 1" },
        { id: 2, name: "ID 2" },
        { id: 3, name: "ID 3" },
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      loop: {
        name: "loop Name",
        quantity: 3,
      },
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      timings: constants.TIMINGS,
      taskNames: constants.TASK_NAMES,
      glCodes: constants.GL_CODES,
    };
  },
  computed: {
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  watch: {
    isEnableAddLoop: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loop.venue_service_id = this.venueServiceId;
          this.loop.loop_id = this.loop_id;
          this.loop.id = this.id;
          this.loop.unique_id = this.unique_id;
          this.loop.name = this.name;
          this.loop.description = this.description;
          this.loop.pits = this.pits;
          this.loop.pit_in_enabled = this.pit_in_enabled;
          this.loop.pit_out_enabled = this.pit_out_enabled;
          this.loop.lap_enabled = this.lap_enabled;
          //this.loop.two_way_id = this.two_way_id;
          //this.loop.two_way_id = 1;
          this.loop.booster_id = this.booster_id;
          this.loop.x_position = this.x_position;
          this.loop.y_position = this.y_position;
          this.loop.gate_time = this.gate_time;
          this.loop.latitude_0 = this.latitude_0;
          this.loop.latitude_1 = this.latitude_1;
          this.loop.longitude_0 = this.longitude_0;
          this.loop.longitude_1 = this.longitude_1;
          this.$forceUpdate();
        }
      },
    },
  },
  mounted() {
    console.log("pits:", this.pits);
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
  },
  methods: {
    handleCheckboxChange(selected) {
      // Reset other checkboxes when one is selected
      if (selected === "pit_in") {
        this.loop.pit_in_enabled = 1;
        this.loop.pit_out_enabled = 0;
        this.loop.lap_enabled = 0;
      } else if (selected === "pit_out") {
        this.loop.pit_out_enabled = 1;
        this.loop.pit_in_enabled = 0;
        this.loop.lap_enabled = 0;
      } else if (selected === "lap") {
        this.loop.lap_enabled = 1;
        this.loop.pit_in_enabled = 0;
        this.loop.pit_out_enabled = 0;
      }
      this.$forceUpdate();
    },
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    deleteProduct(productId, categoryId) {
      this.$emit("deleteProduct", productId, categoryId);
    },
    addLoop() {
      if (!this.$refs.loop_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      for (let key in this.loop) {
        if (this.loop[key] != null && key != "id") {
          formData.append(key, this.loop[key]);
        }
      }

      this.$http({
        method: "post",
        data: formData,
        url: `venues/mylaps/loops${this.loop.id ? "/" + this.loop.id : ""}`,

        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.save();
            this.showSuccess(
              "Loop successfully " + this.loop.id ? "updated" : "created."
            );
            this.loop = {};
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },
    confirmActions(data) {
      if (data.type == "rules") {
        this.loop.inventory_rules.splice(data.id, 1);
      }
      if (this.loop.inventory_rules.length == 0) {
        this.loop.inventory_rules.push({
          weekdays: [],
          start_time: "00:00:00",
          end_time: "23:59:59",
        });
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style scoped>
.v-input--switch {
  .v-input__slot {
    //flex-direction: row-reverse;
  }
}
</style>
